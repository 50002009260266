//Footer

footer{
    width: 100%;
    text-align: center;
    margin-top: auto;
    padding: em(10);
    // padding-bottom: 60px;
    p{
        margin:7px;
        font-size: 12px;
    }
    .footer-icons{
        svg{
            transform: scale(1.5);
        }
    }
    .get-in-touch{
        border: 1px solid $yellow;
        width: fit-content;
        border-radius: 5px;
        margin: auto auto 40px;
        padding: 15px;
        font-size: 1.25rem;
        line-height: 30px;
        svg{
            margin:10px 15px 0 25px;
        }
    }
}