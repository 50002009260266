// Resets
html {
    box-sizing: border-box;
    min-height:100%;
    position:relative;
}
  *, *:before, *:after {
    box-sizing: inherit;
    outline: none; 
}

body {
    margin: 0;
    height:100%;
    
}

h1, h2, h3, p {
    margin-top: 0;
}

img {
    max-width: 100%;
    height: auto;
}
button{
    border: none;
}
