.navbar {
    height: 80px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100000;
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    width: 100%;
    background-color: rgba(5, 25, 53, 0.97);
    .logo {
        position: fixed;
        top: -10px;
        left: 5px;
        right: auto;
        margin: 5px auto auto 0;
        transition: ease;
        transition-duration: 400ms;
        display: flex;
        justify-content: center;
        .cls-1{
            fill: $orange;
            transition-duration: 400ms;
        }

        &:hover, &:active{
            .cls-1{
                fill: rgb(255, 185, 128);
                transition-duration: 400ms;
            }
        }
    }
    .logo-container{
        @media only screen and (min-width: 450px){
            transform: scale(1.3);
            .logo{
                position: fixed;
                top:-40px;
                left: auto;
                right: auto;
            }
        }
        @media only screen and (min-width: 700px){
            transform: scale(1.4);
        }
        @media only screen and (min-width: 900px){
            transform: scale(1.5);
        }
    }
    .menu-bars {
        background: none;
        display: block;
        margin: auto;
        z-index: 99;
        fill:rgba(255, 185, 128, 0.418);
        stroke: $orange;
        stroke-width: 1.5px;
        position: fixed;
        top: 15px;
        left: auto;
        right:10px;
        transform: scale(1.3);
        transition: 350ms;
        &:hover{
            cursor: pointer;
            fill: rgba(255, 185, 128, 0.618);;
        }
    
    }
}


.nav-menu{
    background-color:#051131e5;
    width: 270px;
    height:100vh;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top:auto;
    right:0;
    transition: 850ms;
    z-index: 3;
}
.nav-text{
    list-style: none;
}
.nav-text a{
    display:flex;
    align-items: center;
    margin: 10px auto;
  
}
.nav-background{
    @media only screen and (max-width: 800px){
        height: 100vh;
        width: 100vw;
        background-color: #03133ae5;
        position: fixed;
        z-index: 2;
        transition: ease;
        transition-duration: 400ms;
    }
}
.nav-background-hidden{
    background-color: #03133a00;
    transition: ease;
    transition-duration: 400ms;
}
.nav-menu-items {
    width:95%;
    padding: 30px 15px;
    z-index: 1000000;
}
.navbar-toggle{
    background-color: rgb(79, 77, 77);
    width:100%;
    height: 80px;
    display:flex;
    justify-content: flex-end;
    align-items: center;
}
.menu-bars :hover{
    color:orange;    
}
