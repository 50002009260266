.page-about{
    h1{
        font-size: 1.75rem;
    }
    .tool-kit{
        max-width: 600px;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: start;
        section{
            margin: 10px auto;
            border: 1px solid $silver;
            padding: 20px;
            width: 90%;
        }
        @media only screen and (min-width:400px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
            margin: 10px auto ;
            h2{
                grid-column: 1 / span 2;
            }
        }
       
    }
    .intro{
        margin: 10px 20px 10px 0;
        max-width: 600px;
        @media only screen and (min-width:600px) {
            margin: 10px auto 30px auto;
        }
    }
   @media only screen and (max-width:650px){
       margin-left: 10px;
   }
}