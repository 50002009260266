//wrapper
.wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color:rgb(5, 25, 53);
    overflow: hidden;
}
main {
    margin-bottom: 30px;
    .page-section{
        margin: 100px auto 30px auto;
    }
}
.padding-true{
    @media only screen and (min-width: 800px) {
        padding-right:270px;
        transition: 750ms;
    }
}
.padding-false{
    padding:0;
    transition: 750ms;
    transition-delay: 200ms;

}