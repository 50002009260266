.project{
    margin-bottom: 40px;
    display: block;
    z-index: 1;
}

.project-hide{
    transition:20ms ;
    z-index: 1;
}
.social-icon{
    transform: scale(1.6);
    margin:0 5px;
    margin-right: 40px;


    &:hover{
        color:$blue;
    }
}
.icon{
    transform: scale(1.5);
    z-index: 99999;
    display: inline-block;
    margin: 0 12px;
}
.woo-icon{
    // color: rgb(162, 95, 228);
    transform: scale(7);
    margin: 0 50px;
}
.wp-icon{
    // color:rgb(78, 165, 223);
}
.php-icon{
    // color:rgb(115, 115, 255);
    transform: scale(3.5);
}
.js-icon{
    // color:rgb(244, 255, 128);
}
.react-icon{
    // color: rgb(139, 120, 174);
}
.ps-icon{
    // color:rgb(115, 115, 255);

}
.ai-icon{
    // color:rgb(176, 136, 61);
}
.xd-icon{
    // color:rgb(150, 37, 140);
    transform: scale(1.5);
}
.sass-icon{
    // color:rgb(196, 28, 182);
}
.arrow{
    float: right;
    margin-right: 15px;
    transform: scale(1.75);
}
.mail-icon{
    transform: scale(1.5);
}

.info-icon{

    svg{
        transform: scale(1.2);
    }
}