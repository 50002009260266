.case-study{
    max-width: 1200px;
    margin:  100px auto auto auto;

    h3{
        margin: 40px 10px 15px;
    }
    .project-image{
        width: 100%;
        max-width: 700px;
        display: block;
        margin: auto;
    }
    .project-grid{
        display: grid;
        grid-template-columns: 1fr;
        @media screen and (min-width:800px){
            grid-template-columns: 1fr 1fr;
            align-items: center;
            .project-image{
                grid-row: 1;
                grid-column: 2;
            }

        }
    }
    .projects-details{
        margin: auto 0;
        
        
       
        .overview p{
            margin:10px auto;
            max-width: 90%;
        }

      
       
    }
    .design-section{
        border-radius: 5px;
        margin: auto;
        .wireframes-swipe{
            margin: 30px auto 10px auto;
            padding-top: 20px;
            text-align: center;
        }
        
    }
    .section-header{
        border: dashed 2px $silver;
        border-style: dashed dotted;
        padding: 10px ;
        margin: 10px auto ;
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        h3{
            margin: auto 0;
            @media screen and (max-width:450px){
                font-size: 1rem;
            }
        }
        .plus-icon{
            color:rgb(199, 255, 199);
        }
    }
    .active, .section-header:hover, .section-header:focus, .section-header:active{
        color: $blue;
        border-color: $blue;
        transform: scale(1.02);
    }
    .development-section .section-header{
        margin: 10 auto;
    }
    .mou-slider{
        overflow: hidden;
        max-width:1100px;
        margin: auto;
        border-bottom: 1px solid $yellow;
        border-radius: 5px;
        &:hover, &:focus{
            cursor: grab;
        }
        &:active{
            cursor: grabbing;
        }
        .slick-slide{
            padding: 0 10px;
            img{
                border: 5px solid rgb(66, 66, 66);
                opacity: 0.8;
                transition: 500ms;
                &:hover, &:focus, &:active{
                    opacity: 1;
                    transition: 500ms;
                }
            }
           
        }
        .slick-next {
            right: 10px;
            color: $silver;
            transform: scale(2);
            z-index: 11;
        }
        .slick-prev{
            left:20px;
            color: $silver;
            transform: scale(2);
            z-index: 11;
        }
    }
    .design-section .nav-text{
        margin: auto;
    }
    .code-snippet{
        margin: 15px;
        div{
            border-top: 1px solid $yellow;
            padding-top:10px;
            margin: 30px auto;
        }
    }
    @media only screen and (max-width: 600px) {
        h2{
            font-size: 1.5rem;
        }
      
    }
    @media only screen and (min-width:800px) {
        .intro-grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .section-header{
            width: 70%;
        }
    }

}