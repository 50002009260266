// Variables

// Colors
$headingsColor: rgb(233, 221, 214);
$textColor:#cbcfdd;
// $green: #00a99d;
$yellow: #ffffe9;

$silver: #b0bfc0;
$orange: #ffc66a;
$lightOrange:#c2f6ff;
$blue: #c2f6ff;

// Type
$bodyFont: 'Roboto', sans-serif;
$yellowFont:   'Courier New', Courier, monospace;

// Layout
$section-padding: em(16) em(20);

