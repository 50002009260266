.projects-page{
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1200px;
}



div.project-container{
    transition: ease-in-out;
    transition-duration: 1000ms;
    font-size: 1.25rem;
    // text-align: center;
    border-radius: 5px;
    z-index:1;
    margin: 20px auto;
    padding: 5px;
    width: 85%;
    &:hover, &:active{
        // background-color: rgba(0, 0, 0, 0.7);
        transition: ease-in-out;
        transition-duration: 1000ms;
    }
    .project-info{
        margin-top:25px;
    }
    .project-tools{
        font-size: 0.75rem;
        color: $blue;
        position: relative;
        top: -25px;
        text-align: center;
    }
    img{
        width:auto;
        width: 550px;
        // <a href='https://www.freepik.com/psd/background'>Background psd created by rawpixel.com - www.freepik.com</a>
    }
    .links-container{
        // margin-bottom: 30px;
        .links div{
            margin: 20px auto;
        }
        p{
            margin: 10px;
        }
    }
    @media screen and (min-width:700px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        .project-info{
            grid-column: 1 / span 2;
        }
        div{
            align-self: center;
            .project-links div{
                margin: 10px auto;
            }
        }
            
    }
}
   

@media screen and (min-width:700px){
    .moviena-section .links-container{
        grid-column: 1;
        grid-row: 1;
    }
}

.links-container{
    .links{
        width: 300px;
    }
    // div{
    //     font-size: 14px;
    //     border: 1px solid $silver;
    //     border-radius: 5px;
    //     padding: 10px;
    //     margin:10px auto;
    //     width: 250px;
    //     a{
    //         width: 250px;

    //     }
    //     .social-icon{
    //         margin:0 auto;
    //         transform: scale(1.2);  
    //         float: right; 
    //         margin-left: 3px; 
    //     }
    //     text-align: center;
    // }
}
.project-links{
   
    // font-size: 1.25rem;
    // font-weight: 600;
    color:$silver;
    div{
        transition:350ms ease-in-out;
    }
    .social-icon{
        color: $silver;
    }
    margin: auto;
    &:hover,
    &:active{
        color: rgb(241, 223, 182);
        div{
            transform: scale(1.05);
            transition:350ms ease-in-out;
        }
        .social-icon{
            color: rgb(241, 223, 182);
        }
    }
    
    div{
        border: 1px solid $silver;
        border-radius: 5px;
        padding: 10px;
        margin:10px auto;
        width: 250px;
        .social-icon{
            margin:0 auto;
            transform: scale(1.2);  
            float: left; 
            margin-left: 3px; 
        }
        text-align: center;
    }
}
