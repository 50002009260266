.home-section{
    margin: 0 auto;
    .intro{
        margin: 28vh auto 10vh;
        font-size: 1.25rem;
        max-width: 1200px;
        .line2{
            color: $headingsColor;
            font-size: 2.5rem;
            position: relative;
            top:15px;
            padding: 10px;
            display: flex;
            justify-content: center;
        }
        .line1, .line3, .line4{
            margin-left:15%;
            transition-duration: 400ms;
        }
        .line4{
            margin-top:10px;
        }
        svg{
            display: inline-block;
            max-width:100px;
            position: relative;
            top: 32px;
            margin-bottom:20px;
            fill: none;
        }
        .cls-1{
            
            stroke:$textColor;
            stroke-width: 1px;
        }
        .fill-path{
            fill: $textColor;
            stroke:none;
        }
        @media only screen and (min-width: 600px){
            font-size: 2rem;
            .line2{
                font-size: 3.5rem;
            }
            svg{
                max-width:150px;
                position: relative;
                top: 37px;
            }
        }
        @media only screen and (min-width: 800px){
            font-size: 2.5rem;
            .line2{
                font-size: 4rem;
            }
            svg{
                max-width:200px;
                position: relative;
                top: 37px;
            }
        }
        @media only screen and (min-width: 1000px){
            font-size: 3.5rem;
            .line2{
                font-size: 5rem;
            }
            .line1, .line3, .line4{
                margin-left:20%;
                transition-duration: 400ms;
            }
        }
    }
    a.links div{
        text-align: center;
    }

}
.padding-true .home-section{
    .line1, .line3, .line4{
        @media only screen and (min-width: 800px) and (max-width: 1100px){
            margin-left: 5%;
            transition-duration: 400ms;
        }
      
    }
}
