// Type
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap");
/* 

font-family:     'Courier New', Courier, monospace;;

*/


body {
    font-family: 'Montserrat', sans-serif;
        color: $textColor;
}

h1, h2{
    // font-family: 'Noto Sans JP', sans-serif;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    font-size: 2rem;
    margin: auto ;
    color: $headingsColor;
    border-top: 1px dashed;
    border-bottom: 1px dashed;
    padding: 0 20px;
    max-width: fit-content;
    text-align: center;
}

a{
    text-decoration: none;
    color: currentColor;
}
button{
    margin: 0;
    padding: 0;
}

.letter{
    padding: 0;
    margin: 0;
}

.arrow-code{
    height: 350px;
}
.case-study{
    max-width: 1600px;
}
.page-links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

a.links{
    transition-duration: 400ms;
    div{
        color: $silver;
        border-radius: 0px 15px;
        padding: 10px 25px;
        width: 250px;
        height: 50px;
        line-height: 30px;
        margin: 5px 20px;
        transition: ease;
        transition-duration: 200ms;
        font-size: 1.25rem;
        box-shadow: rgba(146, 199, 255, 0.5) 1px 5px 30px -5px;
        svg{
            float: right;
            margin-top: 3px;
        }
    }
    
    &:hover{
        div{
            // color: rgb(241, 223, 182);
            color: $blue;
            transform: scale(1.03);
            transition: ease-out;
            transition-duration: 200ms;
            border: 1px  $silver;
            border-style: solid dashed;
            border-radius: 0px;
            box-shadow: rgb(146, 198, 255) 2px 10px 20px -15px;
        }
        transform: scale(1.03);
        transition-duration: 200ms;
    }
}